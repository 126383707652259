import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Box, BoxProps } from '../../../atoms'

const HLineRightAnim = keyframes`
  from {
    background-position: left top;
  }
  to {
    background-position: left 10px top;
  }
`

const HLineLeftAnim = keyframes`
  from {
    background-position: left top;
  }
  to {
    background-position: left -10px top;
  }
`

const VLineBottomAnim = keyframes`
  from {
    background-position: right top;
  }
  to {
    background-position: right top 10px;
  }
`

export const HLineLeft: React.FC<BoxProps> = styled(Box)`
  background-image: linear-gradient(90deg, ${({ theme }) => theme.colors.dark} 50%, transparent 50%);
  background-size: 10px 2px;
  animation: ${HLineLeftAnim} 0.75s infinite linear;
`

export const HLineRight: React.FC<BoxProps> = styled(Box)`
  background-image: linear-gradient(90deg, ${({ theme }) => theme.colors.dark} 50%, transparent 50%);
  background-size: 10px 2px;
  animation: ${HLineRightAnim} 0.75s infinite linear;
`

export const VLineBottom: React.FC<BoxProps> = styled(Box)`
  background-image: linear-gradient(0deg, ${({ theme }) => theme.colors.dark} 50%, transparent 50%);
  background-size: 2px 10px;
  animation: ${VLineBottomAnim} 0.75s infinite linear;
`
