import React, { useRef, useState, useEffect, useCallback } from 'react'
import { motion } from 'framer-motion'
// Types
import { ExternalServiceProps } from '../../../../types/blocks'
// Components
import { Flex, Box, Image } from '../../../../atoms'

interface SourceBlockProps {
  sources: ExternalServiceProps[]
}

const sourceVariant = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
}

const SourceBlock: React.FC<SourceBlockProps> = ({ sources }) => {
  const [currentSource, setCurrentSource] = useState(0)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  const goToIdx = useCallback((index) => {
    setCurrentSource(index)
  }, [])

  useEffect(() => {
    resetTimeout()
    timeoutRef.current = setTimeout(() => {
      const idx = sources.length - 1 === currentSource ? 0 : currentSource + 1
      goToIdx(idx)
    }, 4000)

    return () => {
      resetTimeout()
    }
  }, [currentSource])

  return (
    <Box position={'relative'} flex={['0 0 56px', '0 0 78px']} height={[56, 78]} mt={'-12px'}>
      <Box
        position={'absolute'}
        top={4}
        left={0}
        width={'100%'}
        height={'100%'}
        borderStyle={'solid'}
        borderWidth={1}
        borderColor={'dark'}
        borderRadius={'small'}
        backgroundColor={sources[currentSource].color?.hex || 'darkBlue'}
        transition={'background-color 0.5s ease-in-out'}
        boxShadow={'0 8px 0 0 #000'}
        zIndex={0}
      />
      <Flex
        position={'relative'}
        justifyContent={'center'}
        alignItems={'center'}
        height={'100%'}
        borderStyle={'solid'}
        borderWidth={1}
        borderColor={'dark'}
        borderRadius={'small'}
        backgroundColor={sources[currentSource].color?.hex || 'darkBlue'}
        transition={'background-color 0.5s ease-in-out'}
        boxShadow={'0 8px 0 0 #000'}
        zIndex={1}
      >
        {sources.map((s, i) => (
          <motion.div
            key={s.id}
            initial={'hidden'}
            animate={currentSource === i ? 'visible' : 'hidden'}
            transition={{ type: 'tween', ease: 'linear', duration: 0.5 }}
            variants={sourceVariant}
            style={{
              padding: '12px',
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <Image
              alt={s.asset.alt || s.name}
              src={s.asset.url}
              width={s.asset.width}
              height={s.asset.height}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </motion.div>
        ))}
      </Flex>
    </Box>
  )
}

export default SourceBlock
