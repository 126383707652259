import React from 'react'
import { motion } from 'framer-motion'
// Types
import { ImageBasicProps } from '../../../../types/images'
// Components
import { Flex, Box, Image } from '../../../../atoms'
import { HLineLeft, HLineRight, VLineBottom } from '../Destination.styled'

interface DinmoBlockProps {
  logo: ImageBasicProps
}

const DinmoBlock: React.FC<DinmoBlockProps> = ({ logo }) => {
  return (
    <Box position={'relative'} flex={['0 0 80px', '0 0 110px']} height={['80px', '110px']}>
      <motion.div
        initial={{ scale: 1.6 }}
        animate={{ scale: [1.6, 1.7, 1.6] }}
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          backgroundSize: '110%',
          backgroundPosition: 'center',
          backgroundImage: 'url(/assets/images/blurred_circle.png)',
          zIndex: 0,
        }}
        transition={{
          type: 'spring',
          duration: 2.5,
          bounce: 0.25,
          repeat: Infinity,
        }}
      />
      <VLineBottom position={'absolute'} top={'100%'} left={'calc(100%/2)'} width={'1px'} height={[40, 72]} />
      <HLineLeft
        position={'absolute'}
        top={['calc(100% + 39px)', 'calc(100% + 71px)']}
        right={'calc(100%/2)'}
        width={'500px'}
        height={'1px'}
      />
      <HLineRight
        position={'absolute'}
        top={['calc(100% + 39px)', 'calc(100% + 71px)']}
        left={'calc(100%/2)'}
        width={'500px'}
        height={'1px'}
      />
      <Flex
        position={'relative'}
        height={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
        p={4}
        color={'white'}
        borderStyle={'solid'}
        borderWidth={1}
        borderColor={'dark'}
        borderRadius={'small'}
        backgroundColor={'darkBlue'}
        boxShadow={'0 12px 0 0 #000'}
        zIndex={1}
      >
        <Image
          alt={logo.alt || 'DinMo'}
          src={logo.url}
          width={logo.width}
          height={logo.height}
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </Flex>
    </Box>
  )
}

export default DinmoBlock
