import React from 'react'
import styled from 'styled-components'
import { Box, BoxProps } from '../../../../atoms'

export const DestinationBlockBox: React.FC<BoxProps> = styled(Box)`
  &:first-child {
    margin-left: 0 !important;
  }
  &:last-child {
    margin-right: 0 !important;
  }
`
