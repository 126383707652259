import React, { useCallback, useEffect, useRef, useState } from 'react'
// Types
import { DestinationBlockProps } from '../../../../types/blocks'
// Components
import { Flex, Image } from '../../../../atoms'
import { VLineBottom } from '../Destination.styled'
import { DestinationBlockBox } from './DestinationBlock.styled'
import { motion } from 'framer-motion'

const destinationVariant = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
}

const DestinationBlock: React.FC<DestinationBlockProps & { delay: number }> = ({ items, delay }) => {
  const [currentDestination, setCurrentDestination] = useState(0)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  const goToIdx = useCallback((index) => {
    setCurrentDestination(index)
  }, [])

  useEffect(() => {
    resetTimeout()
    timeoutRef.current = setTimeout(() => {
      const idx = items.length - 1 === currentDestination ? 0 : currentDestination + 1
      goToIdx(idx)
    }, delay * 1000)

    return () => {
      resetTimeout()
    }
  }, [currentDestination])

  if (items.length === 0) {
    return null
  }

  return (
    <DestinationBlockBox position={'relative'} flex={['1', '0 0 88px']} mx={2} maxWidth={[64, 88]} mt={[32, 64]}>
      <VLineBottom position={'absolute'} bottom={'100%'} left={'calc(100%/2)'} width={'1px'} height={[32, 64]} />
      <Flex
        pb={'calc(100%)'}
        position={'relative'}
        justifyContent={'center'}
        alignItems={'center'}
        borderStyle={'solid'}
        borderWidth={1}
        borderColor={'dark'}
        borderRadius={'small'}
        backgroundColor={items[currentDestination].color?.hex || 'darkBlue'}
        transition={'background-color 0.5s ease-in-out'}
        boxShadow={'0 8px 0 0 #000'}
        zIndex={1}
      >
        {items.map((d, i) => (
          <motion.div
            key={d.id}
            initial={'hidden'}
            animate={currentDestination === i ? 'visible' : 'hidden'}
            transition={{ type: 'tween', ease: 'linear', duration: 0.5 }}
            variants={destinationVariant}
            style={{
              padding: '10px',
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <Image
              alt={d.asset.alt || d.name}
              src={d.asset.url}
              width={d.asset.width}
              height={d.asset.height}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </motion.div>
        ))}
      </Flex>
    </DestinationBlockBox>
  )
}

export default DestinationBlock
