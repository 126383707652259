import React from 'react'
import { SECTION_MARGIN } from '../../../utils/constants'
// Types
import { SectionDestination } from '../../../types/sections'
// Components
import { StaticImage } from 'gatsby-plugin-image'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Flex, Box, Heading, Button } from '../../../atoms'
import SourceBlock from './SourceBlock'
import DinmoBlock from './DinmoBlock'
import DestinationBlock from './DestinationBlock'
import { HLineRight } from './Destination.styled'

const Destination: React.FC<SectionDestination> = (props) => {
  const { id, sectionId, headline, sources, dinmoLogo, destinationBlocks, button } = props

  return (
    <Box as={'section'} id={sectionId || id} my={SECTION_MARGIN}>
      <Grid>
        <Box position={'relative'}>
          <Box display={['none', 'none', 'block']} position={'absolute'} top={'25%'} left={0} zIndex={0}>
            <StaticImage
              formats={['auto']}
              draggable={false}
              src={'../../../images/synapse_1.png'}
              alt={''}
              placeholder={'none'}
              width={46}
            />
          </Box>
          <Box position={'relative'} zIndex={1}>
            <Row center={'xs'}>
              <Col xs={12} md={8}>
                <Box>
                  <Heading as={'h2'} type={'heading1'} textAlign={'center'}>
                    {headline}
                  </Heading>
                </Box>

                {destinationBlocks.length > 0 && sources.length > 0 && dinmoLogo && (
                  <Box>
                    <Flex
                      justifyContent={'center'}
                      alignItems={'center'}
                      pb={[40, 72]}
                      pt={[8, 9, 80]}
                      mx={[32, 44]}
                      overflow={'hidden'}
                    >
                      <SourceBlock sources={sources} />
                      <HLineRight flex={['0 0 10%', '0 0 88px']} height={'1px'} mt={2} />
                      <DinmoBlock logo={dinmoLogo} />
                    </Flex>
                    {destinationBlocks.length > 0 && (
                      <Flex justifyContent={'space-between'} alignItems={'center'}>
                        {destinationBlocks.map((db, i) => (
                          <DestinationBlock key={db.id} {...db} delay={8} />
                        ))}
                      </Flex>
                    )}
                  </Box>
                )}

                {button && button.length > 0 && (
                  <Flex
                    mt={[6, 8, 10]}
                    width={'100%'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                    gap={4}
                  >
                    {button.map((btn) => {
                      return <Button key={btn.id} {...btn} />
                    })}
                  </Flex>
                )}
              </Col>
            </Row>
          </Box>
        </Box>
      </Grid>
    </Box>
  )
}

export default Destination
